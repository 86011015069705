import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import keycloak from './app/keycloak.js'
import PrivateRoute from './app/PrivateRoute';
import { Navbar } from './app/Navbar.js';

import { AddBoardForm } from './features/board/AddBoardForm.js';
import { BoardList } from './features/board/BoardList.js';

import './App.css';

function App() {
  return (
    <div>
      <ReactKeycloakProvider authClient={keycloak}>
      <Navbar />
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<><BoardList />
              <PrivateRoute>
                <AddBoardForm />
              </PrivateRoute>
              </>} />
          </Routes>
        </div>
      </BrowserRouter>
      </ReactKeycloakProvider>
    </div>
  );
}

export default App;
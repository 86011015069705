import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    boardList: [],
    listStat: 'idle',
    latitude: null,
    longitude: null,
    radius: null,
    currentBoard: null,
    error: null
}

export const fetchBoardList = createAsyncThunk('board/boardList', async (location) => {
    const response = await axios.get('https://www.lightinglake.com/boardsvc/boards', 
        { params: location });
    return response.data;
})

export const addBoard = createAsyncThunk('board/addBoard', async (boardObj) => {
    const token = boardObj.token;
    const board = { title: boardObj.title, content: boardObj.contentBodyString, latitude: boardObj.latitude, longitude: boardObj.longitude }
    const response = await axios.post('https://www.lightinglake.com/boardsvc/board', board, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    
    return response.data;
})

/**
 * 파일을 선택해서 서버로 업로드
 * @param {*} formData 
 * @returns 
 */
export const uploadFile = async (formData) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    const response = await axios.post('https://www.lightinglake.com/filesvc/upload', formData, config);
    return response.data;
}

/**
 * 업로드 된 파일을 다운로드
 * @param {*} downParam 
 */
export const downloadFile = (downParam) => {
    axios({
        url: 'https://www.lightinglake.com/filesvc/download?'.concat(downParam),
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        const contentDisposition = response.headers['content-disposition']; // 파일 이름
        let fileName = 'unknown';
        if (contentDisposition) {
            const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
        if (fileNameMatch)
            fileName = decodeURI(fileNameMatch.split('=')[1].replace(/"/g, ""));
        }

        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        link.style.cssText = 'display:none';
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
}

const boardSlice = createSlice({
    name: 'board',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchBoardList.fulfilled, (state, action) => {
                state.boardList = action.payload;
                state.listStat = 'successed';
            })
            .addCase(fetchBoardList.rejected, (state, action) => {
                console.log(state);
                console.log(action);
                state.listStat = 'successed';
            })
            .addCase(addBoard.fulfilled, (state, action) => {
                state.currentBoard = action.payload;
                state.listStat = 'idle';
            })
    }
})

export const { postUpdated } = boardSlice.actions;

export default boardSlice.reducer